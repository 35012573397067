export type AjaxServiceOptions = {
  body?: DynamicObject | object;
  credentials: RequestCredentials;
  headers: Headers;
  method: string;
};

export type AjaxService = {
  get: <T extends unknown>(url: string, options?: Partial<AjaxServiceOptions>) => Promise<T>;
  post: <T extends unknown>(url: string, options?: Partial<AjaxServiceOptions>) => Promise<T>;
};

class AjaxError extends Error {
  status: number;
  constructor(message: string, status?: number) {
    super();
    this.name = 'AjaxError';
    this.message = message;
    if (status) {
      this.status = status;
    }

    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

/**
 * Basic Ajax Singleton Service
 */
const AjaxService = (): AjaxService => {
  const defaults: AjaxServiceOptions = {
    credentials: 'include',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  };

  const request = async <T extends unknown>(url: string, options: AjaxServiceOptions): Promise<T> => {
    options = { ...defaults, ...options };
    const body = typeof options.body === 'object' ? JSON.stringify(options.body) : options.body;

    const response = await fetch(url, {
      body,
      credentials: options.credentials,
      method: options.method,
      headers: options.headers,
    });

    const data = await response.json();

    if (response.ok) {
      // Handle no-content
      if (response.status === 204) {
        return {} as T;
      }

      return data;
    } else {
      if (response.status === 401) {
        throw 'Unauthorized';
      }

      throw new AjaxError(data, response.status);
    }
  };

  const instance = {
    get: <T extends unknown>(url: string, options: Partial<AjaxServiceOptions> = {}): Promise<T> => {
      options = { ...defaults, ...options, method: 'GET' };
      return request<T>(url, options as AjaxServiceOptions);
    },
    post: <T extends unknown>(url: string, options: Partial<AjaxServiceOptions> = {}): Promise<T> => {
      options = { ...defaults, ...options, method: 'POST' };
      return request<T>(url, options as AjaxServiceOptions);
    },
  };

  return instance;
};

let instance: AjaxService;

export default () => {
  if (instance) {
    return instance;
  }

  instance = AjaxService();

  return instance;
};
