import { format, isWithinInterval, isAfter } from 'date-fns';

import type { BlockoutDate, Bundle, GtEventCreated, Item } from 'types';
import { SEO_TITLE_SUFFIX } from '../../constants';
import { getBundleBySlug, getProductBySlug } from '../services/productsApi';
import { fetchSEO } from '../services/seoApi';

const GATE_LIST = ['DESSY', 'ZOLA', 'KNOT', 'WDWIRE'];
const PPC_GATE = ['COMPETITORS', 'NON', 'NON-BRAND'];
/* eslint max-len: ["error", 200], no-useless-escape: 0 */

/**
 * Sorts an array of arrays, each containing elements of the type represented by `T`, by each value
 * belonging to the index given in `field`
 *
 * Example: Given the following `items`, when `field` is `1` and `order` is `desc`:
 *
 * ```javascript
 * [
 *  [1, 2, 3],
 *  [4, 5, 6],
 *  [7, 1, 9],
 * ]
 * ```
 *
 * The following array would be returned:
 *
 * ```javascript
 * [
 *  [4, 5, 6],
 *  [1, 2, 3],
 *  [7, 1, 9],
 * ]
 * ```
 */
export const sortBy = <T extends unknown>(items: T[][], field: number, order: 'asc' | 'desc' = 'asc') =>
  items.sort((a: T[], b: T[]) => {
    if (a[field] < b[field]) {
      return order === 'asc' ? -1 : 1;
    } else if (a[field] > b[field]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

/**
 * Returns an array of numbers, starting with `start` and incrementing by `increment` until the length
 * of the array equals `size`
 */
export const range = (start: number, increment: number, size: number = 16) => {
  let current = start;
  const range: number[] = [];
  for (let i = 0; i < size; i++) {
    range.push(current);
    current += increment;
  }
  return range;
};

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const lowerCaseFirstLetter = (string: string) => string.charAt(0).toLowerCase() + string.slice(1);

export const capitalizeStringAsTitle = (s: string) => {
  return s
    .split(' ')
    .filter((part) => part !== '' && part !== ' ')
    .map((part) => getStringCapitalizedForTitle(part))
    .join(' ');
};

export const getUrlAsTitle = (url: string) => {
  const parts = url.split('/').filter((part) => part.trim() !== '');

  const endOfPath = parts.pop();

  if (!endOfPath) {
    throw new Error(`Could not derive a title from the given URL: ${url}`);
  }

  return capitalizeStringAsTitle(endOfPath.replace(/-/g, ' '));
};

const getStringCapitalizedForTitle = (word: string) => {
  const lowercasedWords = ['a', 'an', 'and', 'at', 'by', 'but', 'for', 'from', 'of', 'the', 'to'];

  const shouldBeCapitalized = lowercasedWords.every((w) => word !== w);

  if (shouldBeCapitalized) {
    word = capitalizeFirstLetter(word);
  }

  return word;
};

export const getImageUrl = (product: Item, label: string) => {
  const media = product.media ?? [];

  const image = media.find((m) => m.label === label);

  if (image?.url) {
    return image.url;
  }

  return 'https://gentux.imgix.net/no-image-avaiable-jsx.png?auto=format&q=70&fit=clip&w=200';
};

interface PredicateFunction {
  (val: string): boolean;
}

/**
 * Returns a filtering function that tests values against a given list of predicates
 *
 * The returned function expects a string, and will return true if:
 *
 *  - Any string predicate is found within the given string
 *  - Any `PredicateFunction` returns true when called with the given string
 */
export const combinePredicatesUsingOr = (predicates: Array<string | PredicateFunction>) => {
  return (val: string) =>
    predicates.reduce((id: boolean, predicate: string | PredicateFunction) => {
      if (typeof predicate === 'string') {
        return id || val.toLowerCase().includes(predicate.toLowerCase());
      }
      return id || predicate(val);
    }, false);
};

export const getCurrentUrl = () => window.location.origin + window.location.pathname;

export const getCollectionUrl = (category: string) => {
  switch (category) {
    case 'Vest':
    case 'Cummerbund':
      return 'vests-and-cummerbunds';
    case 'Shirt':
      return 'shirts';
    case 'Tie':
      return 'ties';
    case 'Suspenders':
    case 'Belt':
    case 'Lapel Pin':
    case 'Tie Bar':
    case 'Cufflinks':
    case 'Pocket Square':
      return 'accessories';
    case 'Shoe':
    case 'Socks':
      return 'shoes-and-socks';
    default:
      return null;
  }
};

export const slugifySnake = (text: string) => text.split(' ').join('_').toLowerCase();

export const getItemUrl = (item: Item) => {
  if (item.category !== 'preconfigured') {
    return `/collection/${getCollectionUrl(item.category!)}/${item.url_slug}`;
  }

  return `/collection/tuxedos-and-suits/${item.url_slug}`;
};

/**
 * Simple converter from object properties into uri encoded query string:
 * {a: 1, b: "test" } -> "a=1&b=test"
 *
 * similar to old school jquery $.param()
 */
export const param = (obj: DynamicObject): string =>
  encodeURI(
    Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join('&')
  );

/**
 * Simple converter from from query string into { key: value } object
 * a=1&b=test -> {a: 1, b: "test" }
 *
 * similar to old school jquery $.deparam()
 */
export const deparam = (url: string): DynamicObject => {
  const hashes = decodeURI(url)
    .replace('#_=_', '')
    .slice(url.indexOf('?') + 1)
    .split('&');

  return hashes.reduce((obj: DynamicObject, strValue: string) => {
    const [key, value] = strValue.split('=');
    obj[key] = value;

    return obj;
  }, {});
};

export const getUrlParams = () => deparam(window.location.href) as { [key: string]: string };

export const getParameterByName = (name: string): string | null => getUrlParams()[name] ?? null;

export const isCurrentBlockOutDate = (blockoutDate: BlockoutDate, relativeDate?: string | Date) => {
  const startDate = blockoutDate.startDate ?? blockoutDate.start_date ?? '';
  const endDate = blockoutDate.endDate ?? blockoutDate.end_date ?? '';

  return isWithinInterval(relativeDate ?? new Date(), {
    start: startDate,
    end: endDate,
  });
};

export const isThereFutureBlockOutDate = (blockoutDate: BlockoutDate, relativeDate?: string | Date) => {
  const startDate = blockoutDate.startDate ?? blockoutDate.start_date ?? '';
  const currentDate = relativeDate ?? new Date();

  return isAfter(startDate, currentDate);
};

export const hasCurrentBlockOutDates = (blockoutDates: BlockoutDate[], relativeDate?: string | Date) =>
  blockoutDates.some((date) => isCurrentBlockOutDate(date, relativeDate));

export const getCurrentBlockOutDates = (blockoutDates: BlockoutDate[], relativeDate?: string | Date) =>
  blockoutDates.filter((date) => isCurrentBlockOutDate(date, relativeDate));

export const hasFutureBlockOutDates = (blockoutDates: BlockoutDate[], relativeDate?: string | Date) =>
  blockoutDates.some((date) => isThereFutureBlockOutDate(date, relativeDate));

/**
 * Returns an array containing all enumerable values of an object
 *
 * Note: Not to be confused with `Object.entries()`, which returns an array of arrays, each containing
 * the key and value of an enumerable property of the given object
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
 */
export const entries = <T extends { [key: string]: any }>(obj: T): T[keyof T][] => Object.keys(obj).map((k) => obj[k]);

/**
 * Returns a multidimensional array where each top level array contains the values of the nth index
 * of each top level array in the given `matrix`
 *
 * In other words, the columns of the provided array will become the rows of the returned array. For
 * example, given the following two dimensional matrix:
 *
 * ```javascript
 * [
 *  [1, 2, 3],
 *  [1, 2, 3],
 *  [1, 2, 3],
 * ]
 * ```
 *
 * `transpose()` will return the following:
 *
 * ```javascript
 * [
 *  [1, 1, 1],
 *  [2, 2, 2],
 *  [3, 3, 3],
 * ]
 * ```
 */
export const transpose = <T extends unknown>(matrix: T[][]) =>
  matrix.reduce((acc: T[][], row: T[]) => row.map((_: T, i: number) => (acc[i] || []).concat(row[i])), []);

/**
 * Returns an array that contains values unpacked from a given two-dimensional array
 *
 * For example, given the following input:
 *
 * ```javascript
 * [
 *  [1, 2, 3],
 *  [4, 5, 6],
 * ]
 * ```
 *
 * This will be returned:
 *
 * ```javascript
 * [1, 2, 3, 4, 5, 6]
 * ```
 */
export const flatten = <T extends unknown>(arr: T[][]) => arr.reduce((acc: T[], val: T[]) => acc.concat(val), []);

/**
 * Returns a copy of the given array in which only the first occurrence of each unique value is
 * preserved
 *
 * For example, given the following input:
 *
 * ```javascript
 * [2, 1, 2, 3, 2, 1, 4]
 * ```
 *
 * This will be returned:
 *
 * ```javascript
 * [2, 1, 3, 4]
 * ```
 */
export const dedupe = <T extends unknown>(arr: T[]) => arr.filter((elem: T, pos: number) => arr.indexOf(elem) === pos);

/** @inheritdoc */
export const unique = dedupe;

export const isNumber = (value: string) => /^\d+$/.test(value);

/**
 * Reads `document.cookie` for a cookie with the given `name` and returns its decoded value if found,
 * otherwise returns `undefined`
 */
export const getStringCookie = (name: string): string | undefined => {
  const cookie = document.cookie.split('; ').reduce((acc: string, val: string) => {
    const parts = val.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : acc;
  }, '');
  return cookie === '' ? undefined : cookie;
};

/**
 * Reads `document.cookie` for a cookie with the given `name` and returns its JSON decoded value if
 * found, otherwise returns an empty object
 */
export const getCookie = <T extends unknown>(name: string): Partial<T> => {
  const cookie = getStringCookie(name) ?? '{}';

  return JSON.parse(cookie);
};

export const getPathFromUrl = (url: string) => url.split(/[?#]/)[0];

// if the last_click is in the gate list, send them to the gate
// and attach a redirect query param that flows uses to redirect the user to the specified location
export const renderCustomizeLookPath = () => {
  const cookie = getCookie<{
    ref_det1: string;
    ref_det2: string;
    ref_source: string;
  }>('last_click_v1');

  return (cookie &&
    cookie.ref_det1 &&
    GATE_LIST.find((item) => cookie.ref_det1!.toLowerCase().includes(item.toLowerCase())) !== undefined) ||
    (cookie &&
      cookie.ref_det2 &&
      cookie.ref_source &&
      cookie.ref_source.toLowerCase() === 'ppc' &&
      PPC_GATE.find((item) => cookie.ref_det2!.toLowerCase().includes(item.toLowerCase())) !== undefined)
    ? `/app/gate/signup/email?redirect=/customize`
    : `/app/customize`;
};

export const isGroomOrBride = (event: GtEventCreated) =>
  event &&
  event.partyRoleId &&
  event.eventType!.toLowerCase().includes('wedding') &&
  (event.partyRoleId === 1 || event.partyRoleId === 14);

const isJacketSku = (sku: string) => sku.charAt(0) === '1';

export const getJacketSkuFromSkuArray = (skus: string[]) => skus.filter(isJacketSku)[0];

export const getJacketSkuFromSkuList = (skus: string) => {
  const delimiter = skus.indexOf(',') !== -1 ? ',' : '-';
  return skus.split(delimiter).filter(isJacketSku).join();
};

export const getLocalURL = () => {
  // in any instance that the app is being build by NextJS
  // we want to point at the local instance of the app
  // to fetch data. Not our currently deployed
  // versions of the app
  if (process.env.BUILD === 'local') {
    return 'http://localhost:3000';
  }

  if (process.env.BUILD === 'ci') {
    return 'http://app:3000';
  }

  return process.env.NEXT_PUBLIC_LOCAL_URL;
};

export const formatMonth = (month: number, year: number) => {
  return format(new Date(year, month - 1), 'MMM');
};

interface ObjectLiteral {
  [key: string]: any;
}

export const isRegularObject = (subject: unknown): subject is ObjectLiteral => {
  const hasExpectedType = typeof subject === 'object' || typeof subject === 'function';

  if (!hasExpectedType || subject === null) {
    return false;
  }

  return Array.isArray(subject) === false;
};

type FilterValue = {
  displayName: string;
};

type FilterMap = {
  [key: string]: string[] | FilterValue[] | string | number;
};

/**
 * Constructs a query string from a map of filters. Handles both array and single-value filters.
 * For array filters, joins array elements into a comma-separated string.
 * For object arrays, uses 'displayName' property of objects.
 *
 * @param {FilterMap} filtersMap - Object with filter names as keys and filter states as values.
 *                                 Values can be arrays of strings, arrays of objects with 'displayName', or single values.
 *
 * @example
 * // Example input:
 * const filtersMap: FilterMap = {
 *   color: ['red', 'blue'],
 *   type: [{ displayName: 'T-Shirt' }, { displayName: 'Pants' }],
 * };
 * // Returns: "color=red,blue&type=T-Shirt,Pants"
 *
 * @returns {string} Query string representing the filters.
 */
export function constructQueryStringFromFilters(filtersMap: FilterMap): string {
  const params = new URLSearchParams();

  Object.keys(filtersMap).forEach((key) => {
    const value = filtersMap[key];
    if (Array.isArray(value)) {
      if (value.length > 0) {
        // Check if the first element of the array is a FilterValue
        const isFilterValueArray = typeof value[0] === 'object' && 'displayName' in value[0];

        const filterValue = isFilterValueArray
          ? (value as FilterValue[]).map((v) => v.displayName).join(',')
          : (value as string[]).join(',');

        params.set(key, filterValue);
      }
    } else if (value) {
      params.set(key, value.toString());
    }
  });

  return params.toString();
}

export const getSEO = async (pathname: string) => {
  try {
    const seo = await (await fetchSEO(pathname)).json();

    if (pathname?.includes('/collection/') && (pathname.match(/\//g) || []).length === 3) {
      if (pathname?.includes('tuxedos-and-suits')) {
        const bundleRes = await getBundleBySlug(pathname.split('/')[3]);

        if (bundleRes.status !== 200 && bundleRes.status !== 201) {
          throw new Error(bundleRes.statusText);
        }

        const bundle = (await bundleRes.json()) as Bundle;

        return seo.title === ''
          ? {
              title: `${bundle.display_name}${SEO_TITLE_SUFFIX}`,
              metaDescription: `${bundle.display_name} | ${bundle.short_description}`,
            }
          : seo;
      } else {
        const productRes = await getProductBySlug(pathname.split('/')[3]);

        if (productRes.status !== 200 && productRes.status !== 201) {
          throw new Error(productRes.statusText);
        }

        const product = (await productRes.json()) as Item;

        return seo.title === ''
          ? {
              title: `${product.display_name}${SEO_TITLE_SUFFIX}`,
              metaDescription: `${product.display_name} | ${product.short_description}`,
            }
          : seo;
      }
    }

    return seo;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
