import LayoutMain from 'layouts/Main';
import App, { AppContext } from 'next/app';
import Router from 'next/router';

import '@root/lib/bugsnag';
import type { GtUser, SeoData } from 'types';
import AjaxService from 'services/ajax';
import { waitUntilGtagHasLoaded } from 'services/google';
import auth from 'utils/auth';
import { getPathFromUrl, getSEO } from 'utils/utils';

import '../styles/globals.css';

const ajax = AjaxService();

const identify = () => {
  if (window.gt.user && window.analytics) {
    window.analytics.identify(
      window.gt.user.id,
      {
        firstName: window.gt.user.firstName,
        lastName: window.gt.user.lastName,
        email: window.gt.user.email,
        phone: window.gt.user.phone,
        smsOptIn: window.gt.user.smsOptIn,
        primaryEventId: window.gt.user.primaryEventId || '',
        brand: 'menguin',
        emailOptIn: window.gt.user.emailOptIn,
        state: window.gt.user.state,
      },
      {
        // @ts-ignore
        All: true,
        Iterable: {
          phoneNumber: window.gt.user.phone,
        },
      }
    );
  }
};

const fetchSession = async () => {
  try {
    return await ajax.post<Promise<GtUser>>('/api/user/session', {
      credentials: 'same-origin',
    });
  } catch (err) {
    if (err === 'Unauthorized') {
      return null;
    } else {
      throw err;
    }
  }
};

const configureAnalyticsReporting = async () => {
  try {
    await waitUntilGtagHasLoaded();

    gtag('config', process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID ?? '');
  } catch (e) {
    console.error(e);
  }
};

interface Props {
  canonicalUrl: string | null;
  pageProps: unknown;
  pathname: string;
  seo: SeoData;
  err?: Error;
}

export default class extends App<Props> {
  /**
   * Reruns on every /page component load
   */
  static async getInitialProps({ Component, ctx }: AppContext) {
    const canonicalUrl = ctx.asPath?.includes('/app')
      ? null
      : `https://www.menguin.com${(ctx.asPath || '').split('?')[0]}`;

    const props = {
      pathname: ctx.asPath,
      pageProps: {},
      // Pull SEO data from compiled SEO traits file (utils/seo)
      // or cms in Redis cache
      seo: await getSEO(getPathFromUrl(ctx.asPath!)),
      canonicalUrl,
      err: ctx.err,
    };

    if (Component.getInitialProps) {
      const pageProps = {
        ...(await Component.getInitialProps(ctx)),
      };
      return {
        ...props,
        pageProps,
      };
    }

    return {
      ...props,
    };
  }

  /**
   * All actions within componentDidMount happen client side only.
   * _app.tsx.ComponentDidMount will only mount once throughout the
   * lifecycle of the application. Think of this as a "bootstrap"
   * for initial js load within the entire application.
   */
  async componentDidMount() {
    const user = await fetchSession();

    user && auth.storeUser(user);
    window.organization = 'menguin';

    if (!user) {
      window.localStorage.removeItem('jwt');
    }

    /** Initialize Analytics on server load*/
    if (process.env.ENVIRONMENT !== 'production') {
      window.dataLayer = [];
    }

    identify();

    /**
     * This will create a callback that runs everytime
     * a new page is loaded via client side routing.
     * i.e. using <Link>
     */
    Router.events.on('routeChangeComplete', () => {
      window.analytics.page();
      identify();

      window.gt = {
        ...window.gt,
        user,
      };

      if (!user) {
        window.localStorage.removeItem('jwt');
      }
    });

    configureAnalyticsReporting();
  }

  render() {
    const { props } = this;
    const { Component, pageProps, err } = this.props;

    return (
      <LayoutMain {...props}>
        <Component {...pageProps} err={err} />
      </LayoutMain>
    );
  }
}
